/** @jsx jsx */
import React, { JSX } from 'react';
import { jsx } from '@emotion/react';
import { SVGStyles } from './styles';

export function CSS3Icon(): JSX.Element {
    return (
        <svg css={SVGStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.75 479.18">
            <g>
                <path
                    d="M68.37,1c21-.26,42-.08,63-.09.05,6.57.15,13.14-.09,19.71-14.55-.1-29.09,0-43.63.09-.1,9.54-.29,19.08-.2,28.62,14.6.09,29.21.23,43.81.17.17,5.4.11,10.82,0,16.23-20.94-.14-41.88-.08-62.82-.06C68.44,44.07,68.48,22.52,68.37,1Z"
                    transform="translate(0 -0.82)"
                />
                <path
                    d="M138.86.89c21.26,0,42.52-.17,63.79.07-.08,6.21,0,12.41,0,18.62q-23.21-.07-46.42.2,0,3.66,0,7.32c15.45.25,30.89.28,46.33.37.06,12.73,0,25.45.11,38.17q-31.9-.06-63.81.08c-.11-5.14-.12-10.27,0-15.41,14.74,0,29.49.06,44.23-.06.09-3.31.13-6.63.12-9.94-14.81,0-29.63,0-44.44-.05C138.78,27.14,138.78,14,138.86.89Z"
                    transform="translate(0 -0.82)"
                />
                <path
                    d="M209.77,1h63.67c.09,6.24.18,12.48,0,18.71-15.54,0-31.08.08-46.62.11,0,2.55.1,5.1.23,7.65,15.45-.07,30.9.12,46.34.28.14,12.67.24,25.34.06,38q-31.9-.09-63.81,0c-.13-5.12-.17-10.26.05-15.38q22.07.17,44.15,0c0-3.32.11-6.64.08-10-14.77.14-29.55.06-44.33,0C209.57,27.29,209.67,14.14,209.77,1Z"
                    transform="translate(0 -0.82)"
                />
            </g>
            <g>
                <path
                    fill="#0170ba"
                    d="M0,92.46q170.88,0,341.75,0c-6.27,70.85-12.44,141.7-19,212.53Q317,372.9,310.68,440.77C286.37,447.21,262.3,454.55,238,461c-22.32,6.55-44.82,12.48-67.15,19h-.66c-21.25-6.32-42.71-11.88-64-18.2-25.08-6.76-49.94-14.3-75-21C27.93,406.86,25,372.93,22.07,339,16.58,276.31,10.61,213.66,5.33,151,3.5,132.09,2.19,113.16,0,94.33V92.46m171.41,28c-.24,13.61.18,27.23-.24,40.83-39.08-.14-78.17,0-117.25,0,.67,13.21,2.44,26.33,4.26,39.43,36.36,1.35,72.77,0,109.13,1-31.81,14.27-64,27.71-96,41.68-2.74,1.22-5.67,2.14-8.12,3.92.27,14.51,2.61,28.94,3.64,43.42,19.38-.07,38.75-.38,58.13-.41,15.53-.08,31.07.23,46.6-.11-.74,23.86.23,47.75-.5,71.61-10.45-1.55-20.77-3.88-31.18-5.73-8.21-1.79-16.62-2.72-24.71-5.07-1.15-11.45-1.95-22.94-3-34.4a25.89,25.89,0,0,0-7.24-.69c-11.74.07-23.49,0-35.24.14a654.93,654.93,0,0,0,6.5,65.85c11.58,3,23.18,6,34.74,9.13,17.71,4.82,35.64,8.83,53.38,13.57,2.11.45,4.24,1.23,6.43,1,1.18,14.49.27,29.07.49,43.6a88.93,88.93,0,0,0,12.06-2.89C216.94,437.27,250.48,428,284,418.77c1.67-13.2,2.45-26.49,3.77-39.72Q293,318.72,298.48,258.4c4.29-46,8-92,12.32-138C264.33,120.39,217.87,120.26,171.41,120.41Z"
                    transform="translate(0 -0.82)"
                />
            </g>
            <g>
                <path
                    fill="#29a9df"
                    d="M171.41,120.41c46.46-.15,92.92,0,139.39-.06-4.32,46-8,92-12.32,138Q293,318.71,287.81,379c-1.32,13.23-2.1,26.52-3.77,39.72-33.56,9.21-67.1,18.5-100.68,27.62a88.93,88.93,0,0,1-12.06,2.89c-.22-14.53.69-29.11-.49-43.6l1.21-.24c31.23-7.84,62.25-16.47,93.41-24.59,3.94-44.2,8.31-88.38,11.08-132.67-26.5.14-53,.06-79.51.17-3.86,0-7.72,0-11.56-.28,14.26-7.34,29.26-13.16,43.9-19.71,14.05-6.37,28.27-12.34,42.37-18.59,2.72-1.25,5.62-2.29,8-4.15.94-3.45.89-7.06,1.26-10.59.81-11.2,2.25-22.34,2.79-33.55-37.53-.3-75.06,0-112.6-.24C171.59,147.64,171.17,134,171.41,120.41Z"
                    transform="translate(0 -0.82)"
                />
                <path
                    fill="#29a9df"
                    d="M171.6,290.22c18.12-.31,36.25.18,54.38.06-1,18.53-2.55,37-3.51,55.56-11.11,3.63-22.39,6.7-33.59,10.06-6,1.82-12.1,3.26-17.78,5.93C171.83,338,170.86,314.08,171.6,290.22Z"
                    transform="translate(0 -0.82)"
                />
            </g>
            <g>
                <path
                    fill="#cfcfcf"
                    d="M53.92,161.25c39.08,0,78.17-.15,117.25,0,.51,43-.13,86,.43,129-15.53.34-31.07,0-46.6.11-19.38,0-38.75.34-58.13.41-1-14.48-3.37-28.91-3.64-43.42,2.45-1.78,5.38-2.7,8.12-3.92,32-14,64.15-27.41,96-41.68-36.36-1.09-72.77.31-109.13-1C56.36,187.58,54.59,174.46,53.92,161.25Z"
                    transform="translate(0 -0.82)"
                />
                <path
                    fill="#cfcfcf"
                    d="M69.76,316.08c11.75-.18,23.5-.07,35.24-.14a25.89,25.89,0,0,1,7.24.69c1,11.46,1.82,22.95,3,34.4,8.09,2.35,16.5,3.28,24.71,5.07,10.41,1.85,20.73,4.18,31.18,5.73,1,14.52-.75,29.14.92,43.61l-1.21.24c-2.19.18-4.32-.6-6.43-1-17.74-4.74-35.67-8.75-53.38-13.57-11.56-3.15-23.16-6.11-34.74-9.13A654.93,654.93,0,0,1,69.76,316.08Z"
                    transform="translate(0 -0.82)"
                />
            </g>
            <g>
                <path
                    fill="#fff"
                    d="M171.17,161.24c37.54.2,75.07-.06,112.6.24-.54,11.21-2,22.35-2.79,33.55-.37,3.53-.32,7.14-1.26,10.59-2.39,1.86-5.29,2.9-8,4.15-14.1,6.25-28.32,12.22-42.37,18.59-14.64,6.55-29.64,12.37-43.9,19.71,3.84.28,7.7.3,11.56.28,26.5-.11,53,0,79.51-.17-2.77,44.29-7.14,88.47-11.08,132.67C234.27,389,203.25,397.6,172,405.44c-1.67-14.47.08-29.09-.92-43.61,5.68-2.67,11.81-4.11,17.78-5.93,11.2-3.36,22.48-6.43,33.59-10.06,1-18.53,2.5-37,3.51-55.56-18.13.12-36.26-.37-54.38-.06C171,247.24,171.68,204.24,171.17,161.24Z"
                    transform="translate(0 -0.82)"
                />
            </g>
        </svg>
    );
}
