/** @jsx jsx */
import React, { JSX } from 'react';
import { jsx } from '@emotion/react';
import { SVGStyles } from './styles';

export function ReduxIcon(): JSX.Element {
    return (
        <svg css={SVGStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 2373.38">
            <path
                fill="#764abc"
                d="M1732.24,1657.64c92.26-9.55,162.25-89.08,159.06-184.53s-82.71-171.8-178.15-171.8h-6.36c-98.63,3.18-175,85.9-171.8,184.52,3.18,47.72,22.27,89.08,50.9,117.71-108.16,213.16-273.59,369.05-521.74,499.49-168.61,89.08-343.58,120.9-518.55,98.63C402.43,2182.58,291.08,2119,221.08,2014c-101.8-155.89-111.34-324.51-25.44-493.13,60.45-120.9,155.88-210,216.33-254.52C399.25,1225,380.16,1155,370.62,1104.07-90.68,1438.12-43,1889.89,97,2103c105,159.08,318.13,257.71,553.55,257.71,63.62,0,127.25-6.37,190.88-22.27,407.22-79.54,715.81-321.34,890.78-680.84Zm559.92-394.5c-241.79-283.15-598.1-439-1005.31-439h-50.9c-28.63-57.27-89.08-95.45-155.89-95.45h-6.37c-98.61,3.18-175,85.9-171.79,184.53,3.18,95.44,82.71,171.8,178.15,171.8h6.36c70-3.18,130.44-47.72,155.89-108.17h57.27c241.79,0,470.84,70,677.64,206.8,159.06,105,273.59,241.8,337.22,407.23,54.08,133.62,50.9,264.06-6.37,375.42-89.07,168.61-238.59,260.88-435.84,260.88-127.26,0-248.14-38.18-311.78-66.82-35,31.82-98.62,82.71-143.16,114.54,136.81,63.62,276.79,98.62,410.4,98.62,305.41,0,531.29-168.62,617.19-337.24,92.26-184.53,85.89-502.68-152.71-773.1ZM676,1711.71c3.18,95.45,82.71,171.8,178.15,171.8h6.37c98.62-3.17,175-85.9,171.79-184.52-3.17-95.45-82.71-171.8-178.15-171.8h-6.36c-6.37,0-15.92,0-22.27,3.17C695.12,1314,641,1078.61,660.13,824.09,672.84,633.2,736.47,467.76,847.82,331c92.26-117.71,270.41-175,391.31-178.15,337.22-6.37,480.37,413.58,489.92,582.21,41.36,9.54,111.35,31.82,159.07,47.72C1849.94,267.34,1531.81.09,1226.41.09,940.08.09,676,206.89,571,512.31c-146.34,407.23-50.9,798.54,127.26,1107.15-15.9,22.27-25.45,57.27-22.27,92.27Z"
                transform="translate(0 -0.09)"
            />
        </svg>
    );
}
